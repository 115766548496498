import React from 'react'

const Triangle: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'baseline', ...style }}
      {...props}
    >
      <path
        d="M2.47372 18.5L12 2L21.5263 18.5H2.47372Z"
        stroke="#0695D7"
        strokeWidth="2"
      />
      <line
        x1="9"
        y1="12.0498"
        x2="15"
        y2="12.0498"
        stroke="#0695D7"
        strokeWidth="1.5"
      />
      <line x1="12" y1="15" x2="12" y2="9" stroke="#0695D7" strokeWidth="1.5" />
    </svg>
  )
}

export default Triangle
