import React from 'react'

const SignaxLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="42"
      viewBox="0 0 36 42"
      color="white"
      fill="none"
      {...props}
    >
      <path
        d="M8.8954 1.45801C8.83748 1.89079 8.81266 2.34023 8.81266 2.79798C8.81266 7.55862 12.0398 10.8628 18.4528 12.7104L20.2484 13.1932C21.0345 13.4095 21.5889 13.5427 21.9116 13.6759C22.2343 13.7674 22.6481 13.8923 23.0618 14.0754C23.9389 14.4249 24.1706 14.916 24.1706 15.4819C24.1706 16.5389 23.1115 17.0716 21.0345 17.0716C18.2211 17.0716 16.2848 15.7483 15.2256 13.1515L7.33975 17.5127C8.95333 22.2733 13.7941 25.2695 20.8028 25.2695C21.9861 25.2695 23.0949 25.1863 24.1375 25.0198L2.28882e-05 32.3023L8.8954 1.45801Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9069 4.47072C24.0297 2.31512 22.5568 1.20819 20.4799 1.20819C18.8663 1.20819 18.0388 1.91562 18.0388 2.83946C18.0388 3.49696 18.4112 3.988 19.098 4.33756C19.8344 4.68712 21.2163 5.12823 23.2933 5.7025C24.5759 6.0687 25.1551 6.21851 26.0819 6.57639L32.5776 0.217773H32.5279L24.9069 4.47072Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75559 35.2589C3.2438 35.1583 3.64927 35.3054 4.00508 35.5068V33.9732C3.49205 33.8416 3.06176 33.8338 2.46597 33.981C0.976514 34.3605 0.182137 35.5223 0.182137 36.6221C0.182137 37.6367 0.811018 38.0627 1.79572 38.5119C2.08533 38.6513 2.57355 38.8449 2.73077 39.116C2.79697 39.2399 2.82179 39.3561 2.82179 39.4955C2.82179 40.0222 2.3915 40.363 1.79572 40.3862C1.3075 40.4095 0.902043 40.2546 0.504853 40.0609V41.3931C0.951691 41.6332 1.48128 41.7571 2.01914 41.7571C3.60789 41.7571 4.51812 40.6573 4.51812 39.2709C4.51812 37.0248 1.88674 37.4276 1.88674 36.1496C1.88674 35.7314 2.24255 35.3674 2.75559 35.2589Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.65977 41.7561H7.39747V32.4927L5.65977 33.0162V41.7561Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6131 35.4867C14.6297 35.7615 14.6297 36.0443 14.6297 36.3272C14.6297 37.103 14.6049 37.895 14.4725 38.6627C14.1829 40.3598 13.3057 41.7821 11.8163 41.7821C9.44969 41.7821 8.69669 39.0264 8.69669 36.9252C8.69669 34.7513 9.35039 31.842 11.8163 31.1874C13.1568 30.8319 14.0256 31.64 14.3897 33.0946L12.7099 34.226C12.6272 33.628 12.4865 32.828 11.8328 32.9734C10.6495 33.232 10.5585 35.713 10.5585 36.6504C10.5585 37.6364 10.5999 39.9881 11.8494 39.9315C12.7927 39.8911 12.9003 38.4607 12.9168 37.604L11.8163 37.7172V35.8989L14.6131 35.4867Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7189 41.7806L17.559 34.4683C17.6252 34.9107 17.65 35.3783 17.65 35.8374V41.7815H15.9123V29.9515L17.5377 29.462L19.7187 36.739C19.636 36.1797 19.5863 35.612 19.5863 35.0443V28.8443L21.3489 28.3135V41.7806H19.7189Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3291 41.7802L25.9992 39.36L24.2533 39.4432L23.9382 41.7819H22.2011L24.3084 27.4162L25.9208 26.9365L28.1675 41.7802H26.3291ZM25.7345 37.1794C25.5524 35.8311 25.3704 34.5161 25.238 33.1595C25.1883 32.5519 25.1304 31.9277 25.0973 31.3285C25.0146 33.3259 24.7663 35.3151 24.5181 37.2876L25.7345 37.1794Z"
        fill="currentColor"
      />
      <path
        d="M35.187 41.7812L29.7728 25.776L28.1101 26.2757L33.5064 41.7812"
        fill="currentColor"
      />
      <path
        d="M30.6503 41.7817L34.9115 24.2287L32.869 24.8427L28.9887 41.7817"
        fill="currentColor"
      />
    </svg>
  )
}

export default SignaxLogo
