import React from 'react'

const Clip: React.FC<React.SVGProps<SVGSVGElement>> = ({ style, ...props }) => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'baseline', ...style }}
      {...props}
    >
      <line x1="6" y1="10.5" x2="2" y2="10.5" stroke="#0695D7" />
      <line x1="6" y1="0.5" x2="-4.37115e-08" y2="0.499999" stroke="#0695D7" />
      <line x1="5" y1="14.5" x2="3" y2="14.5" stroke="#0695D7" />
      <line x1="5.5" y1="11" x2="5.5" y2="1" stroke="#0695D7" />
      <line x1="7.5" y1="12" x2="7.5" stroke="#0695D7" />
      <line x1="0.5" y1="12" x2="0.5" stroke="#0695D7" />
      <line
        x1="0.353553"
        y1="11.6464"
        x2="3.18198"
        y2="14.4749"
        stroke="#0695D7"
      />
      <line
        x1="4.64645"
        y1="14.4751"
        x2="7.47487"
        y2="11.6466"
        stroke="#0695D7"
      />
      <line x1="2.5" y1="11" x2="2.5" y2="4" stroke="#0695D7" />
    </svg>
  )
}

export default Clip
