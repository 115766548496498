import React from 'react'

const DwgIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 303.188 303.188"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <polygon
          fill="#E4E4E4"
          points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
        />
        <g>
          <path
            fill="#FB9200"
            d="M141.855,124.044H94.237V76.427h47.617L141.855,124.044L141.855,124.044z M99.237,119.044h37.617 V81.427H99.237V119.044z"
          />
          <rect
            x="115.546"
            y="12.631"
            fill="#FB9200"
            width="5"
            height="66.296"
          />
          <rect
            x="32.842"
            y="97.736"
            fill="#FB9200"
            width="63.896"
            height="5"
          />
          <rect
            x="115.546"
            y="121.545"
            fill="#FB9200"
            width="5"
            height="70.296"
          />
          <rect
            x="139.355"
            y="97.736"
            fill="#FB9200"
            width="130.991"
            height="5"
          />
        </g>
        <polygon
          fill="#004A94"
          points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
        />
        <g>
          <g>
            <path
              fill="#A4A9AD"
              d="M112.304,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.605,6.498-18.591,6.498H71.577v-47.979 h16.605c7.701,0,13.646,1.969,17.836,5.907C110.208,235.737,112.304,241.426,112.304,248.864z M98.849,249.324 c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183H84.54v26.877h2.888c3.938,0,6.826-1.143,8.663-3.43 C97.929,257.523,98.849,254.028,98.849,249.324z"
            />
            <path
              fill="#A4A9AD"
              d="M172.72,273.871h-15.324l-4.596-20.905c-0.219-0.875-0.563-2.614-1.033-5.218 s-0.816-4.78-1.033-6.531c-0.176,1.422-0.461,3.189-0.854,5.3c-0.395,2.112-0.783,4.053-1.166,5.825s-1.975,8.948-4.775,21.528 h-15.324l-11.881-47.979h12.504l5.218,24.056c1.182,5.316,1.991,9.55,2.429,12.7c0.283-2.231,0.793-5.262,1.525-9.09 c0.733-3.829,1.416-7.001,2.051-9.517l4.234-18.148h12.012l4.102,18.148c0.699,2.91,1.412,6.268,2.133,10.075 c0.723,3.807,1.203,6.651,1.443,8.533c0.285-2.429,1.063-6.64,2.33-12.635l5.318-24.121h12.502L172.72,273.871z"
            />
            <path
              fill="#A4A9AD"
              d="M210.001,245.779h20.708v25.86c-5.623,1.925-11.804,2.888-18.542,2.888 c-7.396,0-13.11-2.144-17.147-6.432c-4.036-4.288-6.055-10.403-6.055-18.345c0-7.745,2.21-13.772,6.629-18.083 c4.419-4.31,10.611-6.465,18.575-6.465c3.018,0,5.868,0.285,8.549,0.853c2.68,0.569,5.016,1.292,7.007,2.166l-4.102,10.174 c-3.457-1.707-7.253-2.56-11.388-2.56c-3.785,0-6.711,1.231-8.778,3.692c-2.068,2.461-3.102,5.979-3.102,10.551 c0,4.485,0.936,7.904,2.806,10.256c1.871,2.352,4.566,3.528,8.09,3.528c1.925,0,3.697-0.186,5.316-0.558v-7.515h-8.565 L210.001,245.779L210.001,245.779z"
            />
          </g>
        </g>
        <polygon
          fill="#D1D3D3"
          points="219.821,50.525 270.346,50.525 219.821,0 	"
        />
      </g>
    </svg>
  )
}

export default DwgIcon
