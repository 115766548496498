import React from 'react'

const Circle: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'baseline', ...style }}
      {...props}
    >
      <circle cx="10.2" cy="10.6001" r="9" stroke="#0695D7" strokeWidth="2" />
      <line
        x1="3.29289"
        y1="17.6928"
        x2="16.7279"
        y2="4.25777"
        stroke="#0695D7"
        strokeWidth="2"
      />
      <line
        x1="3.70711"
        y1="4.29289"
        x2="17.1421"
        y2="17.7279"
        stroke="#0695D7"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Circle
