import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Link from '../../components/Common/Link'

import Circle from '../../svg/circle'
import Triangle from '../../svg/triangle'
import Clip from '../../svg/clip'
import Unclip from '../../svg/unclip'
import PublishCoordinates from '../../svg/publish-coordinates'
import AcquireCoordinates from '../../svg/acquire-coordinates'
import DwgIcon from '../../svg/dwg-icon'
import RevitIcon from '../../svg/revit-icon'
import NavisIcon from '../../svg/navis-icon'
import SignaxLogo from '../../svg/signax-logo'
import Typography from '../../components/Common/Typography'

import { PathEnum } from '../../types/pages'
import { useTranslations } from '../../hooks/use-translations'

import * as s from '../../pages/news.module.scss'

const WorkingWithRevitProjectCoordinates: React.FC = () => {
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title="REVIT SHARED COORDINATES | How it is configured"
        description="Autodesk Revit is one of the designer's main tools, and for successful interdisciplinary collaboration on construction projects, it is important that all designers work in the same coordinates. We hope that this article will help you correctly set up working with coordinates in Revit"
        keywords="Construction project, Autodesk Revit, project coordinates, construction design software, Navisworks model, construction project management, BIM checker, Revit Shared Coordinates"
      />
      <NewsSectionBlock date="31.01.2024">
        <Typography variant="h1" color="blue">
          Working with Revit Shared Coordinates: Basic Tricks for Effective BIM
          Modeling
        </Typography>
        <StaticImage
          width={160}
          height={160}
          src="../../assets/images/solutions/working-with-revit-shared-coordinates/Valentine_Zusik.jpg"
          alt="Valentine Zusik"
          title="Valentine Zusik"
          placeholder="blurred"
          style={{ borderRadius: '50%' }}
        />
        <Typography variant="body1">Valentina Zusik, BIM Manager</Typography>
        <Typography variant="body1">
          Autodesk Revit has a powerful coordinate toolkit. Skillfully using it
          can help you implement the most complex, cross-discipline construction
          projects. However, even a tiny, unnoticed shift in model coordinates
          can often cause the results of clash checks to be incorrect. Serious
          errors will cause models to "fly apart" during assembly. Adjustment of
          coordinates is significant, but it is unlikely that a BIM manager will
          do it daily because it is enough to set it up correctly once at the
          start of a project.
        </Typography>
        <Typography variant="body1">
          With each new project, you have to refresh your head with many nuances
          to ensure you don't forget anything. You can't "touch" the coordinates
          with your hands to understand how things work - you have to do several
          experiments. And as we all know, there is rarely time to experiment
          because of deadlines. This fact led me to the idea of creating a short
          cheat sheet on the subject of coordinates. This article will share all
          the coordinate tips and tricks I know about working with coordinates
          in Revit.
        </Typography>
        <Typography variant="h2" color="blue">
          Main coordinate points
        </Typography>
        <Typography variant="body1">
          The first thing to remember is that Revit has Site category objects,
          Project Base Point <Circle />, and Survey Point <Triangle /> . Their
          only parameters are x, y, z coordinates, and rotation angle. It is
          these two points that, through their coordinates, can give the model
          its exact position in geospace.
        </Typography>
        <Typography variant="body1">
          Why are there two points? The position of an object on a map can be
          determined by the coordinate information of any one point in relation
          to the chosen coordinate system. But once we manage more than one
          object - for example, two buildings on a plot, or even one building
          plus a terrain model of its plot - it will be necessary to combine
          these objects sooner or later. And the only way to do this is to
          define the common origin that they have in common.
        </Typography>

        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-1.jpg"
            alt="Two coordinate systems in Revit"
            title="Two kinds of points allow you to distinguish between relative and absolute coordinate systems in the Revit model"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Two kinds of points <Circle /> <Triangle /> allow you to distinguish
            between relative and absolute coordinate systems in the Revit model
          </Typography>
        </div>
        <Typography variant="body1">
          If both points can be set to any non-zero coordinates in Revit, it
          suggests that there must still be a "software" zero that cannot be
          moved for the program to work correctly. And there is such a point -
          this is the Internal Origin. Even experienced Revit users often don't
          know about its existence because Revit has no object that points to
          this point. However, this point is where CAD or Revit items are
          imported by default when aligning origin. The point is also detected
          if you place any object in Dynamo at the point with coordinates 0,0,0.
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex items-end">
            <StaticImage
              className="mx-auto"
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-2.jpg"
              alt="Revit coordinate detection"
              title="Internal origin and how to find it"
              placeholder="blurred"
            />
            <StaticImage
              className="mx-auto"
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-3.jpg"
              alt="Revit coordinate detection"
              title="Internal origin and how to find it"
              placeholder="blurred"
            />
            <StaticImage
              className="mx-auto"
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-4.jpg"
              alt="Revit coordinate detection"
              title="Internal origin and how to find it"
              placeholder="blurred"
            />
          </div>
          <Typography variant="body2" color="gray">
            Internal origin and how to find it
          </Typography>
        </div>
        <strong>
          Thus, Revit has three origin points, two of which can be controlled.
          The Survey Point is the origin of the absolute coordinate system in
          relation to the Base Project Point, which acts as the relative
          coordinate system in the model.
        </strong>
        <Typography variant="h2" color="blue">
          Key tools
        </Typography>
        <Typography variant="body1">
          Now, let's understand how to manage the coordinates of the two points
          in Revit.
        </Typography>
        <Typography variant="body1">
          To set the coordinates, we can move both points in clipped or
          unclipped positions and share or receive coordinates between files. Be
          careful because, from version 2020.2, one detail changed in this
          functionality, but I'll mention it during the overview.
        </Typography>
        <Typography variant="h3">
          Moving a points in a clipped position
        </Typography>
        <Typography variant="body1">
          Moving a point in a clipped position <Clip /> is imagined as moving a
          point together with a coordinate system "clipped" to it:
        </Typography>
        <div className={cn(s.columnWrapper, 'is-justify-content-space-around')}>
          <div className="flex flex-col w-100 p-3">
            <StaticImage
              className="mx-auto"
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-5.jpg"
              alt="Project base point"
              title="If you move the Project Base Point, its coordinate values change"
              placeholder="blurred"
            />
            <Typography variant="body1">
              Case1. If you move the Project Base Point <Circle /> ,{' '}
              <strong>its coordinate values change </strong> - the point moves
              in the absolute coordinate system, and the Survey Point stands
              still, representing the zero of this system. In doing so, the
              Project Base Point moves its entire coordinate system behind it,
              which{' '}
              <strong>
                we see as the Internal Origin and model moving following the
                point
              </strong>
              .
            </Typography>
          </div>
          <div className="flex flex-col w-100 p-3">
            <StaticImage
              className="mx-auto"
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-6.jpg"
              alt="Survay Point"
              title="If you move the Survey Point , its coordinates will not change because"
              placeholder="blurred"
            />
            <Typography variant="body1">
              Case 2. If you move the Survey Point <Triangle /> , its{' '}
              <strong>coordinates will not change because</strong> it is moving
              the entire absolute coordinate system attached to it and still
              represents zero. On the other hand, the Project Base Point will
              have its coordinates changed, as it displays its coordinates
              relative to the Survey Point, whose position has changed.
            </Typography>
          </div>
        </div>
        <Typography variant="body1">
          When you move points in a fixed position, the same thing always
          happens - you set the coordinates of the Project Base Point in the
          absolute coordinate system. In essence, it doesn't matter which point
          we move relative to which point; for Revit, the main thing is to
          indicate this mutual displacement.
        </Typography>
        <Typography variant="body1">
          In Case 1, when moving the Project Base Point, we will see the whole
          model move with it, together with the Internal Origin (the last can be
          checked by placing any element at the point 0,0,0 before moving it
          through Dynamo). At the same time, it is known that the Internal
          Origin cannot be moved. This kind of thing in coordinate work in Revit
          usually blows your mind, but there is an explanation. As we have
          already understood - for Revit, it does not matter which point about
          which one we move in a fixed position. That is, visually, we move the
          Project Base Point together with the Internal Origin, but
          mechanically, within Revit, it is the reverse displacement, as in Case
          2, and the Internal Origin does stay in place.
        </Typography>
        <Typography variant="body1">
          Would you agree that it is a bit strange to have Project Base Point
          clipping functionality as in Case 1, which works as Case 2 and does
          not change the Internal Origin position? That's right! And it is this
          detail that has been changed in Revit 2020.2. - The function of
          clipping the Project Base Point was removed, and now it doesn't have a
          paperclip symbol next to it. In fact, it always behaves like an
          Unclipped. What does “Unclipped” mean? Let's read on. But if you still
          don't believe why the clipping function was useless in the case of
          Project Base Point and removed for a reason, here's another great{' '}
          <Link
            href="https://forumn.autodesk.com/t5/revit-architecture-forum/project-base-point-clip-removed-in-revit-2020-2/td-p/9157101"
            rel="nofollow noreferrer"
            target="_blank"
          >
            explanation
          </Link>{' '}
          by the legendary Steve Stafford on the Autodesk forum.
        </Typography>
        <Typography variant="body1">
          But what if you still want to move the Internal Origin together with
          the whole model as if Case 1 had worked appropriately? Use the
          “Relocate Project” function – the first in the Position drop-down:
        </Typography>
        <StaticImage
          src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-7.png"
          alt="Relocate project in Revit"
          title="Relocate project in Revit"
          placeholder="blurred"
        />
        <Typography variant="h3">
          Moving the points in the unclipped position
        </Typography>
        <Typography variant="body1">
          When moving in an unclipped position <Unclip /> , the points are
          already moving independently, and their coordinate systems are
          unclipped and remain in place.
        </Typography>
        <div className={cn(s.columnWrapper, 'is-justify-content-space-around')}>
          <div className="flex flex-col w-100 p-3">
            <StaticImage
              className="mx-auto"
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-8.jpg"
              alt="Project base point"
              title="If you move the Project Base Point, its coordinate values change"
              placeholder="blurred"
            />
            <Typography variant="body1">
              Case 1. If you move the Project Base Point <Circle /> ,{' '}
              <strong>its coordinate values change</strong> - the point moves in
              the absolute coordinate system, and the Survey Point stands still,
              indicating zero of this system. In this case, the model coordinate
              system is not attached to the point, so both{' '}
              <strong>
                the model and the Internal Origin will remain in place
              </strong>
              .
            </Typography>
          </div>
          <div className="flex flex-col w-100 p-3">
            <StaticImage
              className="mx-auto"
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-9.jpg"
              alt="Survay Point"
              title="If you move the Survey Point , its coordinates will not change because"
              placeholder="blurred"
            />
            <Typography variant="body1">
              Case 2. If you move the Survey Point <Triangle /> , its{' '}
              <strong>coordinates change because</strong> it is no longer zero
              of the absolute coordinate system but becomes a point with
              coordinates in this system, the same as the Project Base Point.
            </Typography>
          </div>
        </div>
        <Typography variant="body1">
          Both methods (clipped and unclipped moving) mentioned above are
          "manual" ways of assigning coordinates to points. By setting the
          coordinates of the Project Base Points of the models in a shared
          coordinate system, we can already combine them into a single assembly
          file by Survey Point. But what if there are a lot of models, and you
          don't want to enter coordinates in each one? Or do we not know the
          exact coordinates values at all and only understand how geometrically
          the models should be located relative to each other? There is a third
          way to do this:
        </Typography>
        <Typography variant="h3">
          Acquiring and publishing coordinates between files
        </Typography>
        <Typography variant="body1">
          Here, the coordinates of the Project Base Point are automatically
          determined. To share or receive coordinates between two files, one of
          them needs to be loaded as a link into the other. And at least one
          must have the coordinates configured (the Project Base Point has
          non-zero coordinates).
        </Typography>
        <Typography variant="body1">
          Then you can select "Publish Coordinates" <PublishCoordinates /> if we
          have set the coordinates in the active file and want to pass them to
          the linked file:
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-10.png"
            alt="Publish Coordinates in Revit"
            title="Publish Coordinates in Revit"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          "Acquire Coordinates". <AcquireCoordinates /> if the coordinates are
          in the linked file and we want to receive them in the active file:
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-11.png"
            alt="Acquire Coordinates in Revit"
            title="Acquire Coordinates in Revit"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          In both cases, both files will receive Shared Coordinates after
          manipulation. You can load them into each other by Shared coordinates
          and always see their mutual position. Also, once you receive the
          coordinates, you can further "chain them" with the Publish Coordinates
          function to any number of files you need.
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex justify-center">
            <div className="mr-4">
              <StaticImage
                src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-12.png"
                alt="Shared Coordinates in Revit"
                title="Shared Coordinates in Revit"
                placeholder="blurred"
              />
            </div>
            <StaticImage
              src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-13.png"
              alt="Revit file properties"
              title="Revit file properties"
              placeholder="blurred"
            />
          </div>
          <Typography variant="body2" color="gray">
            Import .rvt By Shared coordinates and Shared Site in Linked model
            Properties
          </Typography>
        </div>
        <Typography variant="body1">
          This creates an entry of information from the coordinates in the file
          that receives the coordinates - Site. There may be more than one if,
          for example, you place identical houses on the master plan, and
          different coordinates must be set in the same file. It is better to
          give each Site a name to avoid confusion.
        </Typography>
        <Typography variant="body1">
          This is probably all there is to know about coordinates. It doesn't
          look complicated at all, especially if you study 'comics'.
        </Typography>
        <Typography variant="h2" color="blue">
          Tips & tricks
        </Typography>
        <Typography variant="body1" className="mb-0">
          So, there are three points and three ways of controlling the
          coordinates. And yet there are still different approaches to the
          question of which points can and cannot be moved and how, where to
          place them, and what to combine them with when building assembly
          models. The most important thing is to understand the general
          mechanisms of work, and then there is a way to solve any problem.
        </Typography>
        <div className="flex items-end">
          <Typography variant="h3" className="mr-2 mb-0">
            Set up the coordinates and build the assembly
          </Typography>
          <DwgIcon style={{ width: 40, height: 40 }} />
          <RevitIcon style={{ width: 40, height: 40 }} />
        </div>
        <Typography variant="body1">
          Placing any number of models on the masterplan can always be narrowed
          down to determining the coordinates for the Revit Site file based on
          the AutoCAD/Civil 3D masterplan file. Since we know that the building
          assembly of each model on the masterplan will be combined from files
          of each building discipline, it is clear that all disciplines should
          get the same coordinates from the Site file.
        </Typography>
        <Typography variant="body1">
          There can, however, be several options for coordinating the DWG master
          plan and the RVT for an assembly.
        </Typography>
        <Typography variant="h3">Case one</Typography>
        <Typography variant="body1">
          You are doing a fast concept, competition, or study project. You have
          a rough DWG file of the masterplan in AutoCAD, for example, from open
          sources like cadmapper.com, but you have no idea about the exact
          coordinates and topography. In this case, the following method is
          suitable:
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-14.png"
            alt="Import Coordinates from AutoCAD to Revit"
            title="Import Coordinates from AutoCAD to Revit"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          1. Self-select the origin point. Shift the entire drawing so that its
          coordinates become 0,0,0. Mark this point for clarity.
        </Typography>
        <Typography variant="body1">
          2. Link CAD into your Revit Site file. Placement: Center to Center
        </Typography>
        <Typography variant="body1">
          3. Acquire Coordinates <AcquireCoordinates /> from dwg. Or manually
          move the survey point <Triangle /> <Clip /> to the desired position
          (we made a big circle there on the 1st step). Project Base Point
          receives the coordinates in the survey system.
        </Typography>
        <Typography variant="body1">
          4. Link one or more RVT building files into our Site RVT file and
          Publish the coordinates <PublishCoordinates /> to each building.
        </Typography>
        <Typography variant="body1">
          The result is a Revit Site file with all the models placed in Shared
          coordinates. The masterplan* or the “neighbour’s building” could be
          loaded into each building model for context – use the same Shared
          Coordinates option for placement. Suppose a topographic survey with
          exact numbers of coordinates appears later. In that case, it will be
          enough to retrieve the coordinates from the topographic survey in our
          Site file and transfer them to the building files again.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-15.png"
            alt="Differing Coordinate Systems for Project and Link"
            title="Differing Coordinate Systems for Project and Link"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          * If in step 3 we used the Acquired Coordinates function from the
          master plan (by creating shared coordinates between DWG and RVT), it
          can be loaded into any model by selecting Placement: “By Shared
          Coordinates". If we simply manually aligned the Survey Point with the
          0,0,0 DWG, then loading will occur with a warning, but actually with
          the same result (as the origin of the World Coordinates of the DWG
          file will align with the Survey Point of our Site file).
        </Typography>
        <Typography variant="h3">Case two</Typography>
        <Typography variant="body1">
          You have a master plan with a topographic survey in exact coordinates.
          You can do the same thing as in the first case, but now we don't have
          to choose the origin point ourselves. The problem is that it is
          usually in real-world coordinates very far away from the building
          itself:
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-16.png"
            alt="Import Coordinates from AutoCAD to Revit"
            title="Import Coordinates from AutoCAD to Revit"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          1. The origin point on the masterplan DWG no longer needs to be marked
          so that the drawing does not become huge in length. At this step,
          ensure it is in place by checking the coordinates of a couple of
          points on the topography drawing.
        </Typography>
        <Typography variant="body1">
          2. Link CAD. Placement: Center to Center
        </Typography>
        <Typography variant="body1">
          3. Acquire Coordinates <AcquireCoordinates /> from the DWG. The
          Project Base Point receives the coordinates in the survey system.
        </Typography>
        <Typography variant="body1">
          The task is almost done - the coordination file already has
          coordinates in the survey system, but controlling the coordinate value
          in such assembly would be inconvenient. The Project base Point is
          located in a completely abstract place - the center of the master plan
          and shows the coordinates of this place. The Survey Point is also
          somewhere far from the buildings. It would be convenient if at least
          one of the points would display actual numerical coordinates of some
          reference point marked on our masterplan, allowing us to compare
          coordinates with those marked on DWG quickly. In practice, therefore,
          the following is also done:
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-17.png"
            alt="Import Coordinates from AutoCAD to Revit"
            title="Import Coordinates from AutoCAD to Revit"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          4. Select a reference point - site corner, topographic cross, or just
          an off-site point with exact marked coordinates on your masterplan -
          and move the Survey Point to this point in an unclipped position{' '}
          <Triangle /> <Unclip /> . The Survey Point then displays the
          coordinates in the survey system exactly as the survey masterplan file
          does. You can verify that the assembly does have shared coordinates
          with the master plan file.
        </Typography>
        <Typography variant="body1">
          5. Don’t forget that all this time, we have only moved the points on
          X,Y, as the DWG file of the master plan is a flat drawing, and we used
          the floor plan view. Set the Survey point to Z coordinates as well.
        </Typography>
        <Typography variant="body1">
          6. Link one or more RVT building files into our RVT, set them on
          places, and then publish coordinates to each <PublishCoordinates /> .
        </Typography>
        <Typography variant="h3">
          Taking the specifics of cloud models into account
        </Typography>
        <Typography variant="body1">
          If you work with cloud models using Autodesk Construction Cloud, the
          main nuance is that the command <AcquireCoordinates /> "Acquire
          Coordinates" works there, but <PublishCoordinates /> "Publish
          coordinates" does not. This is most likely to prevent users from
          accidentally overwriting coordinates in a file they cannot edit (in
          Autodesk Construction Cloud, we can flexibly set permissions for each
          model).
        </Typography>
        <Typography variant="body1">
          You will only have to change tactics at the last step for the
          coordinate setting process. Instead of transferring coordinates from
          the Site model to the buildings file(s), you should open the building
          model file, link the Site model file in it, rotate/move it to the
          desired position, and press <AcquireCoordinates /> "Acquire
          Coordinates".
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-18.jpg"
            alt="Publication of coordinates to cloud models"
            title="Publication of coordinates to cloud models is not available"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Publication of coordinates to cloud models is not available
          </Typography>
        </div>
        <Typography variant="body1">
          A little more complicated manipulation must be done to create multiple
          Sites for the same building file. Autodesk recommends you make
          temporary copies of your building by the number of Sites, Acquire
          Coordinates of each site in each copy, then use "Transfer Project
          Standards" to copy all the sites to one file, and then you can delete
          the temporary copies.
        </Typography>
        <Typography variant="body1" className="mb-0">
          But a strong argument already favouring cloud models in coordination
          assemblies is that Revit from the 2019 version has the feature "Link
          Topography", which allows you to embed a surface from Civil 3D into
          Revit without an intermediate export to DWG. In addition, such
          topography is already displayed just as correctly as its own geometry
          within Revit. And, of course, the topography, when imported, is placed
          according to Shared coordinates if they were already configured in
          Revit. The function is only available for cloud models.
        </Typography>
        <div className="flex items-end">
          <Typography variant="h3" className="mr-2 mb-0">
            Checking how it all worked out
          </Typography>
          <NavisIcon style={{ width: 40, height: 40 }} />
        </div>
        <Typography variant="body1">
          The easiest way to check if the final alignment is correct is to
          export all the models and assemble them in Autodesk Navisworks model.
          The main thing is to remember to export them exactly in Shared
          coordinates:
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-19.png"
            alt="Selecting Shared coordinate in Navisworks"
            title="Selecting Shared coordinate when exporting to NWC from Revit"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Selecting Shared coordinate when exporting to NWC from Revit
          </Typography>
        </div>
        <div className="flex items-end">
          <Typography variant="h3" className="mr-2 mb-0">
            Monitor coordinates in progress
          </Typography>
          <RevitIcon style={{ width: 40, height: 40 }} />
          <StaticImage
            style={{ width: 40, height: 40 }}
            width={40}
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/Dynanmo-Logo.png"
            alt="Dynanmo Logo"
            title="Dynanmo Logo"
            objectFit="contain"
            placeholder="blurred"
          />
          <SignaxLogo style={{ width: 40, height: 40, color: '#102538' }} />
        </div>
        <Typography variant="body1">
          We have set up the coordinates in all the files, and they assemble
          wonderfully. How can we be sure that no one has mistakenly changed the
          coordinates while working with the model? The coordinates of the
          Project Base Point (there can be several, according to the number of
          sites) for the project need to be fixed once in the BEP (BIM Execution
          Plan of the project). And then you must check them all the time.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-20.png"
            alt="Check and tag coordinate values in Revit"
            title="Check and tag coordinate values"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Check and tag coordinate values
          </Typography>
        </div>
        <Typography variant="body1">
          You can create a unique view for checking and place Spot Coordinates
          on it. Revit allows you to set Spot coordinates relative to any of the
          three origin points: Base, Survey, and even the Internal origin.
        </Typography>
        <Typography variant="body1">
          Checks can be automated for a large number of models using Autodesk
          Dynamo. For example, use a script that outputs a list of coordinates
          for all models in a specified folder.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-21.jpg"
            alt="Example script from the dynamo"
            title="Example script from the dynamobim.com forum"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Example script from the dynamobim.com forum
          </Typography>
        </div>
        <Typography variant="body1">
          The SIGNAX Tools Checker also does an excellent job of checking the
          coordinates. It has functionality for custom checks: for the element
          count, properties' presence, and the properties' exact values. In one
          click, you can add the check for the coordinate values and run them
          automatically to check all the disciplines in your building assembly.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/solutions/working-with-revit-shared-coordinates/image-22.png"
            alt="Creating any custom checks via BIM Checker in SIGNAX"
            title="Creating any custom checks via SIGNAX Checker"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Creating any custom checks via SIGNAX Checker
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          We hope this article has provided as much detail as possible on how
          coordinates work in Revit. It's great if the material will save you
          time searching for information in scattered sources, save you from
          re-reading the help manual and experimenting on live projects, or add
          valuable tips to your practice. Don't be afraid to get started in
          Revit: do simple and then complex projects because competent use of
          its features can significantly increase the efficiency of your work
          and construction project management.
        </Typography>
        <Typography variant="body1">
          SIGNAX team has Autodesk Service Provider status and provides BIM
          services for design and on-site. Please, visit{' '}
          <GatsbyLink to={getPath(PathEnum.Bim)} className="has-text-primary">
            SIGNAX services page
          </GatsbyLink>{' '}
          to find out more.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default WorkingWithRevitProjectCoordinates
