import React from 'react'

const Unclip: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'baseline', ...style }}
      {...props}
    >
      <line x1="9" y1="10.5" x2="5" y2="10.5" stroke="#0695D7" />
      <line x1="9" y1="0.5" x2="3" y2="0.499999" stroke="#0695D7" />
      <line x1="8" y1="14.5" x2="6" y2="14.5" stroke="#0695D7" />
      <line x1="8.5" y1="11" x2="8.5" y2="1" stroke="#0695D7" />
      <line x1="10.5" y1="12" x2="10.5" stroke="#0695D7" />
      <line x1="3.5" y1="12" x2="3.5" stroke="#0695D7" />
      <line
        x1="3.35355"
        y1="11.6464"
        x2="6.18198"
        y2="14.4749"
        stroke="#0695D7"
      />
      <line
        x1="7.64645"
        y1="14.4751"
        x2="10.4749"
        y2="11.6466"
        stroke="#0695D7"
      />
      <line x1="5.5" y1="11" x2="5.5" y2="4" stroke="#0695D7" />
      <line
        x1="0.646447"
        y1="13.2529"
        x2="12.6673"
        y2="1.23208"
        stroke="#FF4758"
      />
    </svg>
  )
}

export default Unclip
