import React from 'react'

const PublishCoordinates: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'baseline', ...style }}
      {...props}
    >
      <path d="M3.73608 20.0127L17.8782 5.87056" stroke="#44FFCC" />
      <path
        d="M3.5 0L0.613249 5H6.38675L3.5 0ZM3 4.5L3 20H4L4 4.5H3Z"
        fill="#0695D7"
      />
      <path
        d="M23 20.5L18 17.6132V23.3868L23 20.5ZM18.5 20L3 20V21L18.5 21V20Z"
        fill="#FF4758"
      />
      <path
        d="M9 5L19 5"
        stroke="#0695D7"
        strokeWidth="3"
        strokeMiterlimit="7.6613"
        strokeLinejoin="bevel"
      />
      <path d="M22 5L16 8.4641V1.5359L22 5Z" fill="#0695D7" />
    </svg>
  )
}

export default PublishCoordinates
