import React from 'react'

const AcquireCoordinates: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'baseline', ...style }}
      {...props}
    >
      <path d="M3.79834 20.0762L12.2836 11.5909" stroke="#44FFCC" />
      <path
        d="M3.5 0L0.613249 5H6.38675L3.5 0ZM3 4.5L3 20H4L4 4.5H3Z"
        fill="#0695D7"
      />
      <path
        d="M23 20.5L18 17.6132V23.3868L23 20.5ZM18.5 20L3 20V21L18.5 21V20Z"
        fill="#FF4758"
      />
      <path d="M11 6.5V11.5" stroke="#FFC700" strokeLinecap="round" />
      <path
        d="M10.991 11.5L17.491 11.5"
        stroke="#FFC700"
        strokeLinecap="round"
      />
      <path d="M17.5 11.5V7.5" stroke="#FFC700" strokeLinecap="round" />
      <path d="M14 7.5L17.5 7.5" stroke="#FFC700" strokeLinecap="round" />
      <g filter="url(#filter0_d_0_1)">
        <line
          x1="14"
          y1="7.5"
          x2="14"
          y2="6.5"
          stroke="#FFC700"
          strokeLinecap="round"
        />
      </g>
      <path d="M14 6.5H11" stroke="#FFC700" strokeLinecap="round" />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="9.5"
          y="6"
          width="9"
          height="10"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default AcquireCoordinates
